import * as ReactGA from "react-ga";
import { Cookies } from "react-cookie-consent";

export const initGA = (id: string, path: string) => {
  if (process.env.NODE_ENV === "production") {
    ReactGA.initialize(id);
  } else if (process.env.NODE_ENV === "development") {
    ReactGA.initialize(id, { debug: false });
  }
  ReactGA.set({ anonymizeIp: true });
  ReactGA.pageview(path);
};

export const disableGA = (Cookies: Cookies) => {
  Cookies.remove("_ga");
  Cookies.remove("_gat");
  Cookies.remove("_gid");
};