import React from 'react';
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import CookieConsent, {
  getCookieConsentValue, Cookies,
} from "react-cookie-consent";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './scss/base.scss';
import rocket from "./assets/images/rocket.png";
// import { NONAME } from 'dns';
import { initGA, disableGA } from "./utils/ga-utils";

const About = React.lazy(() => import("./pages/About"));
const Case = React.lazy(() => import("./pages/Case"));
const Cases = React.lazy(() => import("./pages/Cases"));
const Contact = React.lazy(() => import("./pages/Contact"));
const Home = React.lazy(() => import("./pages/Home"));
const JoinUs = React.lazy(() => import("./pages/JoinUs"));
const Resources = React.lazy(() => import("./pages/Resources"));
const Resource = React.lazy(() => import("./pages/Resource"));

function ScrollToTop(props: any) {
  const { pathname } = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return props.children;
}

function App() {
  const handleAcceptCookie = () => {
    console.log("we are at: " + window.location);
    if (process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
      initGA(
        process.env.REACT_APP_GOOGLE_ANALYTICS_ID,
        window.location.pathname + window.location.search
      );
      if (window.location.pathname === "/contact") {
        window.location.reload();
      }
    }
  };
  const handleDeclineCookie = () => {
    disableGA(Cookies);
  };
  React.useEffect(() => {
    const consent = getCookieConsentValue();
    if (process.env.REACT_APP_GOOGLE_ANALYTICS_ID && consent === "true") {
      initGA(
        process.env.REACT_APP_GOOGLE_ANALYTICS_ID,
        window.location.pathname + window.location.search
      );
    }
  }, []);

  React.useEffect(() => {
    console.log("Proudly developed by Polina, Miguel and Tom, with heroic assistance from Nick :)")
  }, [])

  return (
    <>
      <BrowserRouter>
        <ScrollToTop>
          <React.Suspense
            fallback={
              <div
                className={"black--bg"}
                style={{ width: "100vw", height: "100vh" }}
              />
            }
          >
            <Routes>
              <Route path={"/about"} element={<About />} />

              <Route path={"/cases"}>
                <Route path={":id/:section"} element={<Case />} />
                <Route index element={<Cases />} />
              </Route>

              <Route path={"/contact"} element={<Contact />} />

              <Route path={"/join-us"} element={<JoinUs />} />

              <Route path={"/resources"}>
                <Route path={":id"} element={<Resource />} />
                <Route index element={<Resources />} />
              </Route>

              <Route index element={<Home />} />
            </Routes>
          </React.Suspense>
        </ScrollToTop>
      </BrowserRouter>
      <CookieConsent
        enableDeclineButton
        onAccept={handleAcceptCookie}
        onDecline={handleDeclineCookie}
        expires={30}
        containerClasses="col-lg-12"
        contentClasses="text-capitalize"
        buttonText="I accept "
        declineButtonText="I decline"
        style={{
          background: "black",
          justifyContent: "center",
        }}
        contentStyle={{
          flex: "0 0 60%",
          textAlign: "center",
        }}
        buttonStyle={{
          background: "white",
          borderRadius: "1000px",
          padding: "1em 2em",
          minWidth: "10em",
          color: "black",
          fontSize: "1em",
        }}
        declineButtonStyle={{
          expires: 7,
          background: "white",
          borderRadius: "1000px",
          padding: "1em 2em",
          minWidth: "10em",
          color: "black",
          fontSize: "1em",
        }}
      >
        <span style={{ fontSize: "1.6em" }}>
          Innovative websites are cool. Privacy is cool.
        </span>{" "}
        <img src={rocket} alt="🚀" width="20px"></img>
        <br />
        We use third-party cookies to understand how you use our website and to
        enable features such as contact forms.{" "}
        <span style={{ fontSize: "0.6em" }}>
          Our updated privacy policy will be here soon.
        </span>
      </CookieConsent>
    </>
  );
}

export default App;
